import React, { MouseEventHandler, useMemo } from 'react';
import { AvailableIcons, Icon } from '../Icon';

interface ButtonProps {
  id?: string;
  text?: string;
  icon?: AvailableIcons;
  iconPosition?: 'left' | 'right';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'default' | 'secondary' | 'tertiary' | 'info';
  variant?: 'default' | 'danger' | 'callout';
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl';
  activated?: boolean;
  disabled?: boolean;
  width?: string;
  square?: boolean;
  rounded?: boolean;
  styleOverride?: string;
  children?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  id,
  icon,
  iconPosition = 'left',
  text,
  onClick,
  activated,
  type = 'default',
  variant = 'primary',
  size = 'base',
  disabled,
  square,
  rounded,
  width,
  styleOverride,
  children,
}) => {
  let iconColor = 'buttonSecondary';
  let hoveredColor = undefined;
  let typeClasses;
  let variantClasses;

  const roundedClass = useMemo(() => {
    if (rounded) return 'rounded-full';
    if (type == 'tertiary') return 'rounded-lg';
    return ['xs', 'sm', 'base'].includes(size) ? 'rounded-lg' : 'rounded-xl';
  }, [size, type, rounded]);

  // DEFAULT TYPE
  if (type == 'default') {
    typeClasses = ` outline-none text-buttonSecondary enabled:active:shadow-[inset_0px_2px_1px_rgba(0,_0,_0,_0.2)] active:before:hidden focus:outline-[3px]`;

    // DANGER
    if (variant === 'danger')
      variantClasses = `
        bg-[linear-gradient(0deg,rgb(255_255_255/0),rgb(255_255_255/.2)),linear-gradient(0deg,rgb(var(--danger)),rgb(var(--danger)))] [text-shadow:_0px_1px_0px_rgb(255_255_255_/_24%)] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_2px_1px_0px_rgba(255,255,255,0.30)_inset] 
        enabled:hover:shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_0px_10px_0px_rgb(var(--danger)_/_.60),0px_2px_4px_0px_rgba(255,255,255,0.40)_inset] enabled:hover:bg-[radial-gradient(119.9%_100%_at_50%_0%,#FEC0D9_0%,rgba(255,_133,_133,_0.992157)_50%,#FC5E5E_100%)]`;
    // CALLOUT
    else if (variant == 'callout') {
      variantClasses = `
        bg-[radial-gradient(170.26%_142%_at_50%_-33%,_#FFF7D2_0%,_#FFE5AA_25%,_#FFB383_50%,_#E37790_75%,_#5E5BED_100%)] [text-shadow:_0px_1px_0px_rgb(255_255_255_/_24%)] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_2px_1px_0px_rgba(255,255,255,0.30)_inset]
        enabled:hover:shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_0px_10px_0px_rgb(255_203_150_/_.60),0px_2px_4px_0px_rgba(255,255,255,0.40)_inset]
        enabled:active:bg-[radial-gradient(237.56%_198.13%_at_50%_157.5%,#FFF7D2_0%,#FFE5AA_25%,#FFB383_50%,#E37790_75%,#5E5BED_100%)]`;

      // PRIMARY
    } else
      variantClasses = `
        bg-[linear-gradient(0deg,rgb(255_255_255/0),rgb(255_255_255/.2)),linear-gradient(0deg,rgb(var(--buttonPrimary)),rgb(var(--buttonPrimary)))] [text-shadow:_0px_0px_2px_rgb(255_255_255_/_90%)] shadow-[0px_2px_4px_0px]-buttonPrimary 
        enabled:hover:shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_0px_10px_0px_rgb(var(--buttonPrimary)_/_.60),0px_2px_4px_0px_rgba(255,255,255,0.40)_inset] enabled:hover:bg-[linear-gradient(0deg,rgb(255_255_255/0),rgb(255_255_255/.5)),linear-gradient(0deg,rgb(var(--buttonPrimary)),rgb(var(--buttonPrimary)))]
        enabled:active:bg-[linear-gradient(180deg,_rgba(0,_0,_0,_0.33)_0%,rgba(0,_0,_0,_0)_100%),linear-gradient(0deg,rgb(var(--buttonPrimary)),rgb(var(--buttonPrimary)))]
        `;

    // SECONDARY TYPE
  } else if (type == 'secondary') {
    typeClasses = ` border border-buttonSecondary hover:border-transparent bg-[linear-gradient(0deg,rgb(0_0_0_/_0.2),rgb(0_0_0_/_0.2)),linear-gradient(0deg,rgb(var(--buttonSecondary)_/_0.4),rgb(var(--buttonSecondary)_/_0.4))] enabled:active:shadow-[inset_0px_1px_1px_rgb(0_0_0/0.2)] gradient-border-mask ${activated ? 'outline -outline-offset-1' : 'outline-none '}`;

    if (variant === 'danger') {
      variantClasses = `text-danger enabled:hover:bg-[linear-gradient(0deg,rgb(var(--buttonSecondary)/.2),rgb(var(--buttonSecondary)/.2)),linear-gradient(0deg,rgb(var(--primary)/.38),rgb(var(--primary)/.55)),radial-gradient(50%_59.71%_at_50%_100%,rgb(var(--danger)/.50)_0%,rgb(var(--danger)/0)_100%)] enabled:hover:[text-shadow:_0px_-1px_1px_rgb(0_0_0/.2),0px_0px_8px_rgb(var(--danger)/1)] enabled:active:[text-shadow:_none] enabled:active:text-danger/60 enabled:active:bg-[linear-gradient(0deg,rgb(var(--buttonSecondary)/.2),rgb(var(--buttonSecondary)/.2)),radial-gradient(50%_59.71%_at_50%_100%,rgb(var(--danger)/.30)_0%,rgb(var(--danger)/0)_100%)] enabled:hover:before:bg-[radial-gradient(50%_100%_at_50%_0%,rgb(var(--danger)/0.4)_0%,rgb(94_252_214/0.04)_100%)] ${activated ? 'outline-danger shadow-[0_0_6px_rgb(var(--danger))] hover:shadow-[0_0_9px_rgb(var(--danger))]' : ''}`;
      iconColor = 'danger';
    } else {
      iconColor = 'buttonPrimary';
      variantClasses = `text-buttonPrimary enabled:hover:bg-[linear-gradient(0deg,rgb(var(--buttonSecondary)/.2),rgb(var(--buttonSecondary)/.2)),linear-gradient(0deg,rgb(var(--primary)/.38),rgb(var(--primary)/.55)),radial-gradient(50%_59.71%_at_50%_100%,rgb(var(--buttonPrimary)/.4)_0%,rgb(var(--buttonPrimary)/0)_100%)] enabled:hover:[text-shadow:_0px_-1px_1px_rgb(0_0_0/.2),0px_0px_8px_rgb(var(--buttonPrimary)/1)] enabled:active:[text-shadow:_none] enabled:active:text-buttonPrimary/60 enabled:active:bg-[linear-gradient(0deg,rgb(var(--buttonSecondary)/.2),rgb(var(--buttonSecondary)/.2)),radial-gradient(50%_59.71%_at_50%_100%,rgb(var(--buttonPrimary)/.30)_0%,rgb(var(--buttonPrimary)/0)_100%)] enabled:hover:before:bg-[radial-gradient(50%_100%_at_50%_0%,rgb(var(--buttonPrimary)/0.4)_0%,rgb(94_252_214/0.04)_100%)] ${activated ? 'outline-buttonPrimary shadow-[0_0_6px_rgb(var(--buttonPrimary))] hover:shadow-[0_0_9px_rgb(var(--buttonPrimary))]' : ''}`;
    }

    // TERTIARY TYPE
  } else if (type == 'tertiary') {
    typeClasses = `w-auto enabled:active:bg-buttonSecondary/30 enabled:active:text-opacity-[44%] ${activated ? 'outline outline-[3px] outline-offset-2' : 'outline-none'}`;

    if (variant === 'danger') {
      iconColor = 'danger';
      variantClasses = `text-danger enabled:hover:bg-[linear-gradient(0deg,rgb(var(--buttonSecondary)/.2),rgb(var(--buttonSecondary)/.2)),linear-gradient(0deg,rgb(var(--primary)/.38),rgb(var(--primary)/.55)),radial-gradient(50%_59.71%_at_50%_100%,rgb(var(--danger)/.50)_0%,rgb(var(--danger)/0)_100%)] enabled:hover:border-danger/10 enabled:hover:[text-shadow:_0px_-1px_1px_rgb(0_0_0/.2),0px_0px_8px_rgb(var(--danger)/1)] enabled:active:[text-shadow:_none] enabled:active:text-danger/60 ${activated ? 'outline-danger/55' : ''}`;
    } else {
      iconColor = 'buttonPrimary';
      variantClasses = `text-buttonPrimary enabled:hover:bg-[linear-gradient(0deg,rgb(var(--buttonSecondary)/.2),rgb(var(--buttonSecondary)/.2)),linear-gradient(0deg,rgb(var(--primary)/.38),rgb(var(--primary)/.55)),radial-gradient(50%_59.71%_at_50%_100%,rgb(var(--buttonPrimary)/.4)_0%,rgb(var(--buttonPrimary)/0)_100%)] enabled:hover:border-buttonPrimary/10 enabled:hover:[text-shadow:_0px_-1px_1px_rgb(0_0_0/.2),0px_0px_8px_rgb(var(--buttonPrimary)/1)] enabled:active:[text-shadow:_none] enabled:active:text-buttonPrimary/60 ${activated ? 'outline-buttonPrimary/55' : ''}`;
    }
  } else if (type == 'info') {
    iconColor = 'white';
    hoveredColor = 'black';
  }

  // Spell out these classes that are used dynamically so Tailwind doesn't remove them
  // eslint-disable-next-line
  const tailwindClasses =
    'h-18 before:rounded-lg before:rounded-xl h-[34px] h-[60px] h-[70px] h-[84px]';

  const height = useMemo(() => {
    if (type == 'tertiary') return size == 'sm' ? '[26px]' : '[30px]';
    switch (size) {
      case 'xs':
        return '[28px]';
      case 'sm':
        return '[34px]';
      case 'lg':
        return '[50px]';
      case 'xl':
        return '[60px]';
      case '2xl':
        return '[70px]';
      case '3xl':
        return '[84px]';
      default:
        return '[44px]';
    }
  }, [type, size]);

  const iconSize = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'small';
      case 'sm':
        return 'medium';
      case 'lg':
        return 'regular';
      case 'xl':
        return 'regular';
      case '2xl':
        return 'large';
      case '3xl':
        return 'large';
      default:
        return 'regular';
    }
  }, [size]);

  const sizeClasses = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'text-sm h-' + height;
      case 'sm':
        return 'text-base h-' + height;
      case 'lg':
        return 'text-lg h-' + height;
      case 'xl':
        return 'text-lg h-' + height;
      case '2xl':
        return 'text-xl h-' + height;
      case '3xl':
        return 'text-xl h-' + height;
      default:
        return 'text-lg h-' + height;
    }
  }, [size, height]);

  const widthClasses = useMemo(() => {
    if (width) {
      const units = ['px', 'rem', 'em', 'vh', 'vw', 'vmin', 'vmax'];
      const containsUnit = units.some((unit) => width.includes(unit));
      return containsUnit ? 'w-[' + width + ']' : 'w-' + width;
    }
    if ((icon && !text) || square) return 'aspect-square w-' + height;
    else return 'w-full';
  }, [icon, text, height, width, square]);

  const paddingClass = useMemo(() => {
    if ((icon && !text) || square) return 'p-0';
    if (type != 'tertiary') return 'px-3';
    return 'px-1';
  }, [type, icon, text, square]);

  return (
    <button
      id={id}
      onMouseDown={onClick}
      style={{ width }}
      disabled={disabled}
      className={`whitespace-nowrap space-x-0.5 max-w-full relative font-bold flex items-center justify-center transition duration-300 ease-[cubic-bezier(0.05,0.69,0.32,0.98)] select-none 
      ${size !== 'sm' && type !== 'tertiary' ? 'uppercase' : ''}
      ${typeClasses} 
      ${variantClasses} 
      ${sizeClasses} 
      ${widthClasses} 
      ${roundedClass} 
      ${paddingClass}
      before:${roundedClass}
      ${!text && icon ? 'aspect-square' : ''}
      ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
      ${styleOverride}`}
    >
      {icon && (
        <div className={`relative z-10 ${iconPosition == 'right' ? 'order-2' : ''}`}>
          <Icon name={icon} color={iconColor} hoveredColor={hoveredColor} size={iconSize} />
        </div>
      )}

      {text && <div className='z-20'>{text}</div>}

      {children && <div className='z-20'>{children}</div>}
    </button>
  );
};
